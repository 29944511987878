<template>
  <section class="home">
    <el-container class="home-content">
      <el-aside width="200px" class="home-aside">
        <el-menu
          :default-active="this.$route.path"
          router
          text-color="#C0C4CC"
          active-text-color="#fff"
          class="home-nav"
        >
          <el-menu-item index="/home/commodity" v-if="permissionControl(0)">
            <i class="el-icon-s-goods"></i>
            <span>商品管理</span>
          </el-menu-item>
          <el-menu-item index="/home/order" v-if="permissionControl(1)">
            <i class="el-icon-s-order"></i>
            <span>订单管理</span>
          </el-menu-item>
          <el-menu-item index="/home/freight" v-if="permissionControl(2)">
            <i class="el-icon-s-finance"></i>
            <span>运费管理</span>
          </el-menu-item>
          <el-menu-item index="/home/goods" v-if="permissionControl(3)">
            <i class="el-icon-s-home"></i>
            <span>库存管理</span>
          </el-menu-item>
          <el-submenu index="1" v-if="permissionControl(4)">
            <template slot="title">
              <i class="el-icon-s-custom"></i>
              <span> 会员管理</span>
            </template>
            <el-menu-item index="/home/memberinfo">
              会员信息管理
            </el-menu-item>
            <el-menu-item index="/home/memberlist">
              会员筛选查找
            </el-menu-item>
          </el-submenu>
          <el-menu-item index="/home/mechanism" v-if="permissionControl(5)">
            <i class="el-icon-s-shop"></i>
            <span>机构管理</span>
          </el-menu-item>
          <el-menu-item index="/home/permission" v-if="role.role == '老板'">
            <i class="el-icon-s-operation"></i>
            <span>权限管理</span>
          </el-menu-item>
          <el-menu-item index="/home/analysis" v-if="permissionControl(6)">
            <i class="el-icon-s-data"></i>
            <span>历史数据分析</span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-main class="home-main">
        <section class="content-wrapper">
          <header class="search-header">
            <el-input
              placeholder="请输入内容"
              v-model="searchText"
              @keyup.enter.native="search"
            >
              <el-select
                v-model="selectType"
                slot="prepend"
                placeholder="请选择"
              >
                <el-option label="手机号" value="1"></el-option>
                <el-option label="订单号" value="2"></el-option>
              </el-select>
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="search"
              ></el-button>
            </el-input>
          </header>
          <router-view></router-view>
        </section>
      </el-main>
    </el-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      searchText: "",
      selectType: "1",
      role: {},
      routeMap: [
        "commodity",
        "order",
        "freight",
        "goods",
        "memberinfo",
        "mechanism",
        "analysis"
      ]
    };
  },
  methods: {
    search() {
      if (this.selectType === "1") {
        this.$axios({
          url: "/back/user/getUserByTel",
          params: {
            tel: this.searchText
          }
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data !== null) {
              this.$router.push({
                name: "memberorder",
                query: {
                  uid: res.data.data.uid
                }
              });
            } else {
              this.$message({
                type: "error",
                message: "未查询到相应的用户"
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
        });
      } else {
        this.$axios({
          url: "/back/order/order/getOrder",
          params: {
            oId: this.searchText
          }
        }).then(res => {
          if (res.data.code == 200) {
            if (res.data.data !== null) {
              this.$router.push({
                name: "checkorder",
                query: {
                  uid: res.data.data.address.uid,
                  oid: res.data.data.oid
                }
              });
            } else {
              this.$message({
                type: "error",
                message: "未查询到相应的订单"
              });
            }
          } else {
            this.$message({
              type: "error",
              message: res.data.msg
            });
          }
        });
      }
    },
    permissionControl(index) {
      if (this.role.role == "老板" || this.role.permission.includes(index))
        return true;
      else return false;
    }
  },
  created() {
    this.role = this.$store.state.role;
    this.$router.push({
      name: this.routeMap[this.role.permission[0]]
    });
  }
};
</script>

<style lang="less">
@bgcolor: rgba(0, 25, 54, 0.884);
.home {
  .home-content {
    overflow: scroll;
    min-height: calc(100vh - 120px);

    .home-aside {
      .home-nav {
        border: 0;
        text-align: left;
        height: 100%;
      }

      .el-menu {
        background-color: rgb(29, 44, 63);
      }
    }

    .home-main {
      background-color: #f1f2f7;
      min-width: 940px;

      .content-wrapper {
        width: 86%;
        margin: 0 auto;
        min-width: 900px;

        .search-header {
          margin-bottom: 40px;
        }
        .el-select .el-input {
          width: 100px;
        }
        .input-with-select .el-input-group__prepend {
          background-color: #fff;
        }
      }
    }
  }
}
.el-menu-item.is-active,
.el-submenu__title:hover,
.el-menu-item:hover {
  background-color: rgba(0, 24, 54, 0.562) !important;
}
</style>
